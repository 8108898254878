<script lang="ts" setup>
import { computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import useStore from '@/store';
import TaskOnMap from './TaskOnMap.vue';
import ArrowLeftIcon from './icons/ArrowLeftIcon.vue';
import ArrowRightIcon from './icons/ArrowRightIcon.vue';
import BlackoutBottom from './BlackoutBottom.vue';
import { PageName } from '@/types';
import { useHapticFeedback } from '@/directives/haptic';
import CompressedImage from './CompressedImage.vue';

const router = useRouter();
const route = useRoute();
const store = useStore();

const locations = computed(() =>
  store.locations.filter(location => location.isSeason === false),
);
const locationId = computed(() => {
  const id = Number(route.params.locationId);
  if (isNaN(id)) {
    router.replace({
      name: PageName.World,
    });
  }
  return id;
});

const locationIndex = computed(() =>
  locations.value.findIndex(location => location.id === locationId.value),
);
const currentLocation = computed(() => locations.value[locationIndex.value]);

const spaces = computed(() => {
  return currentLocation.value?.tasks.map(() => Math.random()) ?? [];
});

const mapSrc = computed(() => {
  return currentLocation.value
    ? `tasks/maps/${currentLocation.value.id}`
    : null;
});

const currentLocationNumber = computed(() => {
  return (
    locations.value.findIndex(
      location => location.id === currentLocation.value?.id,
    ) + 1
  );
});

const isThisLastLocation = computed(
  () => currentLocationNumber.value === locations.value.length,
);

const goPrevious = () => {
  useHapticFeedback('soft');
  const previousLocation = locations.value[locationIndex.value - 1];
  const lastLocation = locations.value[locations.value.length - 1];
  const targetLocation = previousLocation ? previousLocation : lastLocation;
  router.push({
    name: PageName.Location,
    params: {
      locationId: targetLocation.id,
    },
  });
};

const goNext = () => {
  useHapticFeedback('soft');
  const nextLocation = locations.value[locationIndex.value + 1];
  const firstLocation = locations.value[0];
  const targetLocation = nextLocation ? nextLocation : firstLocation;
  router.push({
    name: PageName.Location,
    params: {
      locationId: targetLocation.id,
    },
  });
};

const goBack = () => {
  useHapticFeedback('soft');
  const previousRoutePath = router.options.history.state.back;
  const previousRoute = previousRoutePath
    ? router.resolve(previousRoutePath.toString()).matched[0]
    : null;
  if (previousRoute?.name === PageName.World) {
    router.back();
  } else {
    router.push({
      name: PageName.World,
    });
  }
};
</script>

<template>
  <div
    class="tasks-map"
    :class="{
      'tasks-map--last-location': isThisLastLocation,
    }"
  >
    <div class="back-button" @click="goBack">
      <ArrowLeftIcon />
    </div>
    <div class="animated-content" :key="currentLocationNumber">
      <CompressedImage class="background" :src="mapSrc" alt="Map" />

      <div class="background-blackout" />
      <div class="content">
        <div class="header">
          <h1>{{ currentLocation?.title }}</h1>
          <div class="locations-counter">
            {{ currentLocationNumber }}/{{ locations.length }}
          </div>
        </div>
        <div v-if="currentLocation" class="list">
          <div
            v-for="(id, index) in currentLocation.tasks"
            :key="id"
            class="item"
          >
            <div class="space" :style="{ flex: spaces[index] }" />
            <TaskOnMap class="task" :taskId="id" />
            <div class="space" :style="{ flex: 1 - spaces[index] }" />
          </div>
        </div>
      </div>
    </div>
    <div class="controls">
      <button class="previous-button" @click="goPrevious">
        <ArrowLeftIcon />
      </button>
      <button class="next-button" @click="goNext">
        <ArrowRightIcon />
      </button>
    </div>
    <BlackoutBottom />
  </div>
</template>

<style lang="scss" scoped>
$cubic-bezier: cubic-bezier(0.26, 0, 0.07, 1);
$animation-duration: 2s;

@keyframes map-animation {
  0% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes background-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}

@keyframes content-animation {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.tasks-map {
  position: relative;
  height: 100vh;

  .back-button {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10vw;
    height: 10vw;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5vw);
    top: 4vw;
    left: 4vw;
    border-radius: 100%;
    z-index: 1000;
    cursor: pointer;

    svg {
      width: 6vw;
      height: 6vw;
      fill: white;
    }
  }

  .animated-content {
    animation: map-animation $animation-duration $cubic-bezier forwards;
  }

  .background {
    position: fixed;
    height: 100vh;
    width: 100vw;
    left: 0;
    top: 0;
    object-fit: cover;
    animation: background-animation $animation-duration $cubic-bezier forwards;
  }

  .background-blackout {
    position: fixed;
    height: 30vh;
    width: 100vw;
    left: 0;
    top: 0;
    background: linear-gradient(0deg, rgba(20, 20, 20, 0) 0%, #141414 106.35%);
    opacity: 0.3;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    height: calc(100vh - 40vw);
    animation: content-animation $animation-duration $cubic-bezier forwards;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4vw;
    width: 100vw;
    height: 10vh;
    margin-top: 5vh;
    z-index: 1000;
  }

  .locations-counter {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(black, 0.5);
    backdrop-filter: blur(5vw);
    border-radius: 100vw;
    border: 1px solid rgba(white, 0.2);
    animation: menu-animation 800ms;
    pointer-events: auto;
    font-size: 3vw;
    font-weight: 700;
    padding: 1vw 2vw;
    margin-top: 1vw;
  }

  .list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 50vh;
    width: 80vw;
    margin-bottom: 5vh;
  }

  .item {
    display: flex;
  }

  .controls {
    position: fixed;
    display: flex;
    width: 100vw;
    bottom: 27vw;
    padding: 0 4vw;
    left: 0;
    z-index: 1000;
    pointer-events: none;
  }

  .previous-button,
  .next-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(black, 0.5);
    backdrop-filter: blur(5vw);
    border-radius: 100vw;
    border: 1px solid rgba(white, 0.2);
    animation: menu-animation 800ms;
    pointer-events: auto;
    padding: 3vw 4vw;

    svg {
      width: 6vw;
      height: 6vw;
      fill: white;
    }
  }

  .next-button {
    margin-left: auto;
  }
}
</style>
